// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualSearch from "../../blocks/VisualSearch/src/VisualSearch";
import FormApprovalWorkflow from "../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import ProjectReporting from "../../blocks/ProjectReporting/src/ProjectReporting";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import EditingFeatures3 from "../../blocks/EditingFeatures3/src/EditingFeatures3";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Analytics from "../../blocks/analytics/src/Analytics";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import KanbanBoard from "../../blocks/KanbanBoard/src/KanbanBoard";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import ProjectPortfolio from "../../blocks/ProjectPortfolio/src/ProjectPortfolio";
import GanttChart3 from "../../blocks/GanttChart3/src/GanttChart3";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import LiveChat2 from "../../blocks/LiveChat2/src/LiveChat2";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ProjecttaskTracking2 from "../../blocks/ProjecttaskTracking2/src/ProjecttaskTracking2";
import ReceiptCustomisation from "../../blocks/ReceiptCustomisation/src/ReceiptCustomisation";
import Payments from "../../blocks/Payments/src/Payments";
import LiveChatSummary from "../../blocks/LiveChatSummary/src/LiveChatSummary";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ProjectNotes from "../../blocks/ProjectNotes/src/ProjectNotes";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";



const routeMap = {
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualSearch:{
 component:VisualSearch,
path:"/VisualSearch"},
FormApprovalWorkflow:{
 component:FormApprovalWorkflow,
path:"/FormApprovalWorkflow"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
ProjectReporting:{
 component:ProjectReporting,
path:"/ProjectReporting"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
EditingFeatures3:{
 component:EditingFeatures3,
path:"/EditingFeatures3"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
KanbanBoard:{
 component:KanbanBoard,
path:"/KanbanBoard"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
ProjectPortfolio:{
 component:ProjectPortfolio,
path:"/ProjectPortfolio"},
GanttChart3:{
 component:GanttChart3,
path:"/GanttChart3"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
LiveChat2:{
 component:LiveChat2,
path:"/LiveChat2"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
ProjecttaskTracking2:{
 component:ProjecttaskTracking2,
path:"/ProjecttaskTracking2"},
ReceiptCustomisation:{
 component:ReceiptCustomisation,
path:"/ReceiptCustomisation"},
Payments:{
 component:Payments,
path:"/Payments"},
LiveChatSummary:{
 component:LiveChatSummary,
path:"/LiveChatSummary"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
ProjectNotes:{
 component:ProjectNotes,
path:"/ProjectNotes"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},

  Home: {
component:LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
